//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 250px;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header,
#menu-modal .modal-header {
    .logo {
        img {
            width: 215px;
        }
    }
}