//=================================================
// Clients
//=================================================

.clients {
	padding-top: 4.211rem;
	padding-bottom: 4.211rem;

	.clients-items {
		@extend .d-flex;
		@extend .justify-content-between;
		@extend .align-items-center;
		margin-top: -$space_md * 0.5;
		margin-bottom: -$space_md * 0.5;
		margin-left: -3.5%;
		margin-right: -3.5%;

		@media #{$media_xxl} {
			margin-left: -2.2%;
			margin-right: -2.2%;
		}

		@media #{$media_lg} {
			flex-wrap: wrap !important;
			justify-content: space-around !important;
		}

		@media #{$media_sm} {
			margin-left: -$wrapper_p_l_sm;
			margin-right: -$wrapper_p_r_sm;
		}

		.clients-item {
			@extend .d-flex;
			width: 100%;
			padding-left: 3.5%;
			padding-right: 3.5%;
			padding-top: $space_md * 0.5;
			padding-bottom: $space_md * 0.5;

			@media #{$media_xxl} {
				padding-left: 2.2%;
				padding-right: 2.2%;
			}

			.clients-item-content {
				max-width: 11.632rem;
				margin: auto;

				@media #{$media_lg} {
					max-width: 9.5rem;
				}

				img {
					max-width: 100%;
					max-height: 5.579rem;
				}
			}

			@media #{$media_lg} {
				flex: 0 0 33%;
				max-width: 33%;
			}

			@media #{$media_sm} {
				flex: 0 0 50%;
				max-width: 50%;
				padding-left: $wrapper_p_l_sm;
				padding-right: $wrapper_p_r_sm;
			}
		}
	}
}

.marquee-wrapper {
	--size: clamp(10rem, 1rem + 40vmin, 30rem);
	--gap: calc(var(--size) / 14);
	--duration: 60s;
	--scroll-start: 0;
	--scroll-end: calc(-100% - var(--gap));

	display: flex;
	flex-direction: column;
	gap: var(--gap);
	margin: auto;
	max-width: 100vw;

	.marquee {

		display: flex;
		overflow: hidden;
		user-select: none;
		gap: var(--gap);
		mask-image: linear-gradient(var(--mask-direction, to right),
				hsl(0 0% 0% / 0),
				hsl(0 0% 0% / 1) 20%,
				hsl(0 0% 0% / 1) 80%,
				hsl(0 0% 0% / 0));

		.marquee__group {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: space-around;
			gap: var(--gap);
			min-width: 100%;
			animation: scroll-x var(--duration) linear infinite;
			height: 10rem;
			padding: 2rem 0;

			.logo {
				display: grid;
				place-items: center;
				border-radius: 0.5rem;
				height: 100% !important;
				filter: grayscale(1);
			}
		}

		@keyframes scroll-x {
			from {
				transform: translateX(var(--scroll-start));
			}

			to {
				transform: translateX(var(--scroll-end));
			}
		}
	}
}

.clients-bottom-no-space {
	margin-bottom: -$main_padding_b;
}